import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TopMenu = () => {

  let cc = JSON.parse(localStorage['category']);

  // console.log(cc[0]);



  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark p-0">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          Home
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav">

            {cc[0].cate.map((item) => {
              return (
                <li key={item.id} className="nav-item dropdown">
                  <button
                    className="btn nav-link dropdown-toggle fw-bold"
                    id="navbarDropdown"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                  >
                    {item.name}
                  </button>


                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                    {
                      item.sub.map((items, index) => (

                        <li key={items.id}>
                          <Link className="dropdown-item" to="/products/" state={items} onClick={() => {
                            localStorage.category_code = items.category_code

                          }}>
                            {items.name}
                          </Link>
                        </li>

                      ))}

                  </ul>



                </li>
              )
            })}



          </ul>


        </div>
      </div>
    </nav>
  );
};

export default TopMenu;
