
const USER_DETAILS = "user_details";

export const getUserDetails = () => {
  try {
    const userDetails = localStorage.getItem(USER_DETAILS);
    return userDetails != null ? JSON.parse(userDetails) : null;
  } catch (e) {

  }
};

export const checkLogin = () => {
  try {
    const userDetails = localStorage.getItem(USER_DETAILS);
    return userDetails != null ? true : false;
  } catch (e) {

  }
};


export const setUserDetails = (value) => {
  try {
    localStorage.setItem(USER_DETAILS, JSON.stringify(value));
  } catch (e) {
    // saving error
  }
};

export const logout = () => {
  try {
    localStorage.clear();
    console.log("Done.");

  } catch (e) {
    console.log(e);
  }
  // alert('Successfully Logout!');
  // console.log("Done.");

};

