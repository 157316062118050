import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const OrderDetails = () => {
  const location = useLocation();
  const { state } = location;

  const uniqueId = state.uniqueId;

  const [data, setData] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const base = "https://dtserver41.com/apps/electromart/img/";

  const baseUrl = "https://dtserver41.com/apps/electromart/appcode/";

  const code = { uniqueId: uniqueId };



  const getOrder = async () => {
    try {
      setIsLoaded(true);
      const res = await fetch(
        baseUrl + "fetch_order_details.php", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: code
        })
      });

      const result = await res.json();
      console.log(result);
      setData(result.orders);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    getOrder();

  }, []);

  return (
    <div className="container mb-3">
      <h4 className="my-3">Orders</h4>
      <div className="row g-3">

        {data.map((item, index) =>

          <div className="col-md-6">
            <div className="card">
              <div className="row g-0">
                <div className="col-md-3 text-center">
                  <img
                    src={base + item.product_pic}
                    className="img-fluid"
                    alt="..."
                  />
                </div>
                <div className="col-md-9">
                  {/* <div className="card-header">
                    <div className="small">
                      <span className="border bg-secondary rounded-left px-2 text-white">
                        Order ID
                      </span>
                      <span className="border bg-white rounded-right px-2 me-2">
                        #123456
                      </span>
                      <span className="border bg-secondary rounded-left px-2 text-white">
                        Date
                      </span>
                      <span className="border bg-white rounded-right px-2">
                        25 Sep 20, 12:34 PM
                      </span>
                    </div>
                  </div> */}
                  <div className="card-body">
                    <h6>
                      <Link to="/" className="text-decoration-none">
                        {item.name}
                      </Link>
                    </h6>
                    <div className="small">
                      <span className="text-muted me-2">Size:</span>
                      <span className="me-3">{item.size}</span>
                      <span className="text-muted me-2">Price:</span>
                      <span className="me-3">{item.price}</span>
                      <span className="text-muted me-2">Quantity:</span>
                      <span className="me-3">{item.quantity}</span>
                      {/* <span className="text-muted me-2">Color:</span>
                      <span className="me-3">
                        <span className="bg-primary px-1 rounded">
                          &nbsp;&nbsp;&nbsp;
                        </span>
                      </span> */}
                    </div>
                    <div className="mt-2"></div>
                  </div>
                  {/* <div className="card-footer d-flex justify-content-between">
                  <div>
                    <span className="me-2">Status:</span>
                    <span className="text-success">
                      <i className="bi bi-check-circle-fill me-1"></i>
                      Completed
                    </span>
                  </div>
                  <div>
                    <span className="me-2">Invoice:</span>
                    <span className="text-success">
                      <Link to="/invoice">
                        <i className="bi bi-receipt-cutoff me-1"></i>
                        Download
                      </Link>
                    </span>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>

        )}

      </div>
    </div>
  );
};

export default OrderDetails;
