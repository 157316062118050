
const base = "https://dtserver41.com/apps/allbaba/img/";
const baseUrl = "https://dtserver41.com/apps/allbaba/appcode/";

let aa = [];
let cat = [];
let pro = [];
let banner = [];

let newItem, ss;

fetch(baseUrl + "banner.php")
  .then(response => response.json())
  .then(data => {
    // newItem = JSON.stringify(data);

    data.forEach((item) => {
      aac(base + item.banner);

    });


  })
  .catch(error => console.error(error));

function aac(img) {
  newItem = {
    to: "/",
    img: img,
    title: "",
    description: "",
  }
  aa.push(newItem);

  localStorage['banner'] = JSON.stringify(aa);
  banner = JSON.stringify(aa);
};


fetch(baseUrl + "fetch_cat_and_subcat.php", {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
}).then((response) => response.json())
  .then((jsonResponse) => {
    cat.push(jsonResponse);

    localStorage['category'] = JSON.stringify(cat);
  });





export const data = {
  banner: JSON.parse(localStorage['banner']),
  iconProducts: [
    {
      to: "/product/detail",
      img: "IconLaptop",
      title: "Laptop",
      text: " Upto 20% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-primary",
    },
    {
      to: "/product/detail",
      img: "IconHeadset",
      title: "Headset",
      text: " Upto 50% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-secondary",
    },
    {
      to: "/product/detail",
      img: "IconPhone",
      title: "Phone",
      text: " Upto 20% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-danger",
    },
    {
      to: "/product/detail",
      img: "IconTv",
      title: "TV",
      text: " Upto 25% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-warning",
    },
    {
      to: "/product/detail",
      img: "IconDisplay",
      title: "Display",
      text: " Upto 30% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-info",
    },
    {
      to: "/product/detail",
      img: "IconHdd",
      title: "HDD",
      text: " Upto 15% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-success",
    },
    {
      to: "/product/detail",
      img: "IconUpcScan",
      title: "UPC Scan",
      text: " Upto 45% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-muted",
    },
    {
      to: "/product/detail",
      img: "IconTools",
      title: "Tools",
      text: " Upto 50% off",
      tips: "Sony, Dell, Lenovo",
      cssClass: "text-primary",
    },
  ],
  products: [
    {
      id: 1,
      sku: "FAS-01",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_red_480x400.webp",
      price: 180,
      originPrice: 200,
      discountPrice: 20,
      discountPercentage: 10,
      isNew: true,
      isHot: false,
      star: 4,
      isFreeShipping: true,
      description:
        "Nulla sodales sit amet orci eu vehicula. Curabitur metus velit, fermentum a velit ac, sodales egestas lacus. Etiam congue velit vel luctus dictum. Pellentesque at pellentesque sapien.",
    },
    {
      id: 2,
      sku: "FAS-02",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_grey_480x400.webp",
      price: 475,
      originPrice: 0,
      discountPrice: 0,
      discountPercentage: 0,
      isNew: false,
      isHot: true,
      star: 3,
      isFreeShipping: true,
      description:
        "Maecenas suscipit volutpat gravida. Nulla hendrerit nisi a lectus blandit aliquam. Integer enim magna, consequat sed justo nec, auctor sagittis urna.",
    },
    {
      id: 3,
      sku: "FAS-03",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_black_480x400.webp",
      price: 1900,
      originPrice: 2000,
      discountPrice: 100,
      discountPercentage: 0,
      isNew: true,
      isHot: true,
      star: 2,
      isFreeShipping: true,
      description:
        "Vivamus sapien eros, molestie sed lacus vitae, lacinia volutpat ipsum. Nam sollicitudin lorem eget ornare vulputate.",
    },
    {
      id: 4,
      sku: "FAS-04",
      link: "/product/detail",
      name: "Great product name goes here",
      img: "../../images/products/tshirt_green_480x400.webp",
      price: 10,
      originPrice: 0,
      discountPrice: 0,
      discountPercentage: 0,
      isNew: false,
      isHot: false,
      star: 0,
      isFreeShipping: false,
      description:
        "Morbi lobortis velit non consectetur porta.|Duis auctor risus ac purus vehicula tempor.|Fusce at ipsum a leo tempor malesuada.|Curabitur tincidunt justo vel volutpat suscipit.",
    },
  ],
  blogBanner: [
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-1.webp",
      title: "First slide label",
      description: "Nulla vitae elit libero, a pharetra augue mollis interdum",
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-2.webp",
      title: "Second slide label",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-3.webp",
      title: "Third slide label",
      description: "Praesent commodo cursus magna, vel scelerisque nisl.",
    },
  ],
  blogList: [
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-1.webp",
      title:
        "It is a long established fact that a reader will be distracted by the readable content",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
      publishDate: "Jul 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 2,
    },
    {
      to: "/blog/detail",
      img: "../../images/blog/nature-2.webp",
      title:
        "Contrary to popular belief, Lorem Ipsum is not simply random text",
      description:
        "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour",
      publishDate: "Aug 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 3,
    },
    {
      to: "/",
      img: "../../images/blog/nature-3.webp",
      title: "The standard chunk of Lorem Ipsum used since the 1500s",
      description:
        "It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.",
      publishDate: "Sep 05, 2020",
      tags: ["Branding", "Design"],
      commentCount: 4,
    },
  ],
};
