import { lazy, useEffect, useState } from "react";
import { data } from "../../data";
import { Link, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { addToCart, cartReducer } from '../../redux/cartSlice';

import { useParams } from "react-router-dom";


const Paging = lazy(() => import("../../components/Paging"));
const Breadcrumb = lazy(() => import("../../components/Breadcrumb"));
const FilterCategory = lazy(() => import("../../components/filter/Category"));
const FilterPrice = lazy(() => import("../../components/filter/Price"));
const FilterSize = lazy(() => import("../../components/filter/Size"));
const FilterStar = lazy(() => import("../../components/filter/Star"));
const FilterColor = lazy(() => import("../../components/filter/Color"));
const FilterTag = lazy(() => import("../../components/filter/Tag"));
const FilterClear = lazy(() => import("../../components/filter/Clear"));
const CardServices = lazy(() => import("../../components/card/CardServices"));
const CardProductGrid = lazy(() =>
  import("../../components/card/CardProductGrid")
);
const CardProductList = lazy(() =>
  import("../../components/card/CardProductList")
);
const SizeChart = lazy(() => import("../../components/others/SizeChart"));
const base = "https://dtserver41.com/apps/allbaba/img/";
const baseUrl = "https://dtserver41.com/apps/allbaba/appcode/";

const Search = () => {

  const location = useLocation();
  const { state } = location;



  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const cart = useSelector((cartReducer) => cartReducer.cart);

  const path = window.location.href.slice(window.location.origin.length);


  const code = { keyword: path.split("=")[1] };

  const getpro = async () => {

    try {
      setIsLoaded(true);
      const res = await fetch(
        baseUrl + "search_products.php", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: code
        })
      });

      const result = await res.json();
      console.log(result);
      setData(result);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(false);
    }
  };

  useEffect(() => {

    getpro();

  }, []);

  const onPressaddCart = (id, name, photo_link, price, size) => {
    // console.log(price);
    dispatch(addToCart({
      id, name, photo_link, price, size
    }));
  };

  // console.log(price)
  return (
    <div>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb rounded-0">
          <li className="breadcrumb-item">
            <Link to="/" title="Home">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">

            Search

          </li>

        </ol>
      </nav>
      <div className="container-fluid mb-3">
        <div className="row">

          <div className="col-md-12">
            <div className="row">
              <div className="col-7">
                <span className="align-middle fw-bold">

                </span>
              </div>
              <div className="col-5 d-flex justify-content-end">

              </div>
            </div>
            <hr />
            <div className="row g-3">

              {data.status == 1 &&

                data.pro.map((product, index) =>

                  <div className="col-md-3" key={index}>
                    <div className="card">
                      <Link to="/product/detail" state={product}>
                        <img src={base + product.photo_link} className="card-img-top" resizeMode={'cover'} style={{
                          width: '100%',
                          height: 200,

                        }} />
                      </Link>
                      {product.isNew && (
                        <span className="badge bg-success position-absolute mt-2 ms-2">
                          New
                        </span>
                      )}
                      {product.isHot && (
                        <span className="badge bg-danger position-absolute r-0 mt-2 me-2">
                          Hot
                        </span>
                      )}
                      {(product.discountPercentage > 0 || product.discountPrice > 0) && (
                        <span
                          className={`rounded position-absolute p-2 bg-warning  ms-2 small ${product.isNew ? "mt-5" : "mt-2"
                            }`}
                        >
                          -
                          {product.discountPercentage > 0
                            ? product.discountPercentage + "%"
                            : "$" + product.discountPrice}
                        </span>
                      )}
                      <div className="card-body">
                        <h6 className="card-subtitle mb-2">
                          <Link to="/product/detail" state={product} className="text-decoration-none">
                            {product.name}
                          </Link>
                        </h6>
                        <div className="my-2">
                          <span className="fw-bold h5">Rs. {product.price.split(",")[0]}</span>
                          {product.originPrice > 0 && (
                            <del className="small text-muted ms-2">${product.originPrice}</del>
                          )}
                          <span className="ms-2">
                            {Array.from({ length: product.star }, (_, key) => (
                              <i className="bi bi-star-fill text-warning me-1" key={key} />
                            ))}
                          </span>
                        </div>
                        <div className="btn-group  d-flex" role="group">
                          <button
                            type="button"
                            className="btn btn-sm btn-primary"
                            title="Add to cart"
                            onClick={() => {
                              onPressaddCart(product.id, product.name, product.photo_link, product.price.split(",")[0], product.size.split(",")[0]);

                            }}
                          >
                            Add to cart <i className="bi bi-cart-plus" />
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {data.status == 2 &&

                <div className="col-md-12">
                  <p>{data.msg}</p>
                </div>
              }

            </div>
            <hr />

          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
