import { lazy, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkLogin, getUserDetails, logout } from "../utils/LocalStorage";
import { useDispatch, useSelector } from 'react-redux';
import { cartReducer, removeItem, incrementQuantity, decrementQuantity } from '../redux/cartSlice';

const Search = lazy(() => import("./Search"));




const Header = () => {

  const [username, setUsername] = useState(false);

  const dispatch = useDispatch();

  const getLogin = () => {

    const user = checkLogin();
    // console.log(user)
    setUsername(user);
  };

  const doLogout = (item) => {
    logout();
    setUsername(null);
  };

  useEffect(() => {
    window.scrollTo();
    getLogin();
  }, [username]);


  let shopping;
  let c_len = 0;

  const cart = useSelector((cartReducer) => cartReducer.cart);

  c_len = cart.length;

  // localStorage.shopping_cart != undefined ?
  //   c_len = localStorage.shopping_cart.length : c_len = 0;


  // let shopping_cart = JSON.parse(localStorage['shopping_cart']);



  return (
    <header className="p-3 border-bottom bg-light">
      <div className="container-fluid">
        <div className="row g-3">
          <div className="col-md-3 text-center">
            <Link to="/">
              <img alt="logo" src="../../images/icon.png" style={{ width: '10%' }} />
            </Link>
          </div>
          <div className="col-md-5">
            <Search />
          </div>
          <div className="col-md-4">
            <div className="position-relative d-inline me-3">
              <Link to="/cart" className="btn btn-primary">
                <i className="bi bi-cart3"></i>
                <div className="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-circle">
                  {c_len}
                </div>
              </Link>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-secondary rounded-circle border me-3"
                data-toggle="dropdown"
                aria-expanded="false"
                aria-label="Profile"
                data-bs-toggle="dropdown"
              >
                <i className="bi bi-person-fill text-light"></i>
              </button>
              <ul className="dropdown-menu">

                {/* <li>
                  <Link className="dropdown-item" to="/account/profile">
                    <i className="bi bi-person-square"></i> My Profile
                  </Link>
                </li> */}



                {username ?
                  <li>
                    <Link className="dropdown-item" to="/account/orders">
                      <i className="bi bi-list-check text-primary"></i> Orders
                    </Link>
                  </li>
                  :
                  <li></li>
                }

                {/* <li>
                  <Link className="dropdown-item" to="/account/wishlist">
                    <i className="bi bi-heart-fill text-danger"></i> Wishlist
                  </Link>
                </li> */}

                <li>
                  <hr className="dropdown-divider" />
                </li>


                {username ?

                  <li>
                    <Link className="dropdown-item" onClick={() => {
                      doLogout();
                    }}>
                      <i className="bi bi-door-closed-fill text-danger"></i>
                      Logout
                    </Link>
                  </li>

                  :
                  <li>
                    <Link className="dropdown-item" to="/account/signin">
                      <i className="bi bi-door-closed-fill text-danger"></i>
                      Login
                    </Link>
                  </li>
                }

              </ul>
            </div>
            {/* <Link to="/account/signin">Sign In</Link> |{" "}
              <Link to="/account/signup"> Sign Up</Link> */}
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
