import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: [],
        wishList: [],
    },
    reducers: {
        addToCart: (state, action) => {
            // console.log(state);
            console.log(action);
            const itemInCart = state.cart.find((item) => item.id === action.payload.id);
            if (itemInCart) {
                itemInCart.quantity++;
            } else {
                state.cart.push({ ...action.payload, quantity: 1 });
            }
            localStorage['shopping_cart'] = JSON.stringify(state.cart);
        },
        incrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            item.quantity++;
            localStorage['shopping_cart'] = JSON.stringify(state.cart);
        },
        decrementQuantity: (state, action) => {
            const item = state.cart.find((item) => item.id === action.payload);
            if (item.quantity === 1) {
                item.quantity = 1
            } else {
                item.quantity--;
            }
            localStorage['shopping_cart'] = JSON.stringify(state.cart);
        },
        removeItem: (state, action) => {
            const removeItem = state.cart.filter((item) => item.id !== action.payload);
            state.cart = removeItem;
            localStorage['shopping_cart'] = JSON.stringify(state.cart);
        },
        allRemoveCartItem: (state, action) => {
            state.cart = [];
            localStorage['shopping_cart'] = [];
        },
        addToWishlist: (state, action) => {
            console.log(state);
            const itemInwishList = state.wishList.find((item) => item.id === action.payload.id);
            if (itemInwishList) {
                // itemInwishList.quantity++;
            } else {
                state.wishList.push({ ...action.payload });
            }


        },
        removeWishlist: (state, action) => {
            const removeWishlist = state.wishList.filter((item) => item.id !== action.payload);
            state.wishList = removeWishlist;
        },
    },
});

export const cartReducer = cartSlice.reducer;
export const {
    addToCart,
    incrementQuantity,
    decrementQuantity,
    removeItem,
    allRemoveCartItem, addToWishlist, removeWishlist
} = cartSlice.actions;